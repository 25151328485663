import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';
import axios, { AxiosHeaders } from 'axios';
import { store } from '../store';
import { actions } from '../ducks/auth';
import { getAuthHeaders } from '../utils/auth.utils';
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const url = window.location.href || '';
    if (url.includes('super-admin')) {
      const superAdminToken = localStorage.getItem('superAdminAccessToken');
      if (superAdminToken) {
        headers.set('authorization', `Bearer ${superAdminToken}`);
        return headers;
      }
    }

    const token = localStorage.getItem('accessToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
      return headers;
    }

    return headers;
  },
  paramsSerializer: (params) => {
    return queryString.stringify(params);
  },
  async responseHandler(response) {
    const data = await response.clone().json();
    const url = window.location.href || '';

    if (response.status === 401) {
      try {
        const refreshToken = url.includes('super-admin')
          ? localStorage.getItem('superAdminRefreshToken')
          : localStorage.getItem('refreshToken');

        const refreshEndpoint = url.includes('super-admin')
          ? '/v1/admin/refresh-token'
          : '/v1/user/refresh-token';

        const refreshResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}${refreshEndpoint}`,
          {},
          {
            headers: getAuthHeaders({} as AxiosHeaders, refreshToken || ''),
          },
        );

        const { accessToken, refreshToken: newRefreshToken } = refreshResponse.data;
        if (url.includes('super-admin')) {
          localStorage.setItem('superAdminAccessToken', accessToken);
          localStorage.setItem('superAdminRefreshToken', newRefreshToken);
        } else {
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', newRefreshToken);
        }

        const retryResponse = await fetch(response.url, {
          ...response,
          headers: getAuthHeaders(response.headers as unknown as AxiosHeaders, accessToken),
        });

        return await retryResponse.json();
      } catch (error) {
        console.error('Error refreshing token:', error);
        store.dispatch(actions.signOut());
        return { error: { status: 401, data: 'Unauthorized-Token Expired' } };
      }
    }

    return data;
  },
});

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery,
  endpoints: () => ({}),
});
