import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  MenuItem,
  Typography,
  Grid,
  Button,
  Stack,
  CardContent,
  FormControl,
  SelectChangeEvent,
} from '@mui/material';

import { IconCalendarEvent } from '@tabler/icons-react';

import { SchedulePopup } from '@widgets/Popup/Schedule/Schedule';
import { AuthenticatedPath } from '@shared/constants/routes.constants';
import PageContainer from '@shared/components/container/PageContainer';
import Breadcrumb from '@shared/layouts/FullLayout/shared/breadcrumb/Breadcrumb';
import CustomSelect from '@shared/components/forms/theme-elements/CustomSelect';
import CustomFormLabel from '@shared/components/forms/theme-elements/CustomFormLabel';
import CustomTextField from '@shared/components/forms/theme-elements/CustomTextField';
import BlankCard from '@shared/components/shared/BlankCard';

import { useCreateCampaign } from '@features/Admin/Campaign/hooks';
import { CampaignFieldsError, FormFields } from '@shared/types/admin/campaign';
import Spinner from '@shared/components/spinner/Spinner';
import {
  CallScheduleRequestDto,
  useAgentProfileListControllerAgentProfileListQuery,
  useGetAllAgentsControllerGetAllAgentsQuery,
} from '@shared/services/apiService/apiService';
import { CampaignTextField } from '@shared/components/campaignTextField/campaignTextField';
import { CampaignRetryFields } from '@shared/components/campaignRetryFields/CampaignRetryFields';
import { CampaignFileField } from '@shared/components/campaignFileField/CampaignFileField';
import { endTime, initialSchedule, startTime } from '@shared/constants/initialSchedule';
import { useAppSelector } from '@shared/store';

export const CreateCampaignPage: React.FC = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();

  const [campaignFieldError, setCampaignFieldError] = useState<CampaignFieldsError>({
    retryIntervalInMinutes: [],
    retryMaxNumber: [],
    retriesPerDay: [],
    editErrorMessage: '',
    leadErrors: '',
  });

  const BCrumb = [
    {
      to: AuthenticatedPath.CAMPAIGN,
      title: t('campaignPage.header.campaign'),
    },
    {
      title: t('action.create'),
    },
  ];

  const [showScheduleDilaogFlag, setShowScheduleDialogFlag] = useState(false);
  const [excelFieldError, setExcelFieldError] = useState('');
  const [agent, setAgent] = useState('');
  const [isFile, setIsFile] = useState(false);
  const [agentName, setAgentName] = useState('');
  const [productDetails, setProductDetails] = useState<string>('');
  const [templateTextFieldValue, setTemplateTextFieldValue] = useState('');
  const [campaignName, setCampaignName] = useState<string>('');
  const [campaignNameError, setCampaignNameError] = useState('');
  const [retryMaxNumberValue, setRetryMaxNumberValue] = useState('');
  const [retriesPerDayValue, setRetriesPerDayValue] = useState('');
  const [retryIntervalInMinutesValue, setRetryIntervalInMinutesValue] = useState('');

  const { data: agentList } = useGetAllAgentsControllerGetAllAgentsQuery();
  const { data: profilesList } = useAgentProfileListControllerAgentProfileListQuery();

  const mappedAgent = useMemo(() => {
    const selectedAgent = agentList?.find((a) => agent === a.id);
    if (selectedAgent?.mission) {
      setProductDetails(selectedAgent.mission.intro || '');
    }

    return {
      name: selectedAgent?.name || '',
      profileId:
        profilesList?.find((profile) => selectedAgent?.profile === profile.name?.toString())?.id ||
        '',
      voiceId: selectedAgent?.voiceId || '',
      languages: ['eng'],
    };
  }, [agent, agentList, profilesList]);

  const { onSubmit, setValue, control, isLoading } = useCreateCampaign({
    selectedAgent: mappedAgent,
    productDetails,
    setCampaignFieldError,
  });

  const agentListLength = useMemo(() => agentList?.length, [agentList]);
  const currentCompany = useAppSelector((state) => state.auth.authenticatedUserCompany);

  useEffect(() => {
    if (agentList && agentListLength) {
      setAgent(agentList[0].id);
      setAgentName(agentList[0].mission!.humanName);
      setValue('agentId' as FormFields, agentList[0].id);
    }
  }, [agentList]);

  const [schedule, setSchedule] = useState<CallScheduleRequestDto>(initialSchedule);

  const closeDialog = () => {
    setShowScheduleDialogFlag(false);
  };

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setValue(name as FormFields, value);

    if (name === 'title') {
      setCampaignName(value);
    }
  };

  const handleAgentChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setAgent(value as string);
    let chosenAgent = agentList?.filter((currentAgent) => currentAgent.id == value);
    if (chosenAgent) {
      setAgentName(chosenAgent[0].mission!.humanName);
    }
    setValue(name as FormFields, value);
  };

  const handleSubmit = () => {
    setCampaignFieldError({});
    if (
      !campaignName ||
      !retryMaxNumberValue ||
      !retriesPerDayValue ||
      !retryIntervalInMinutesValue
    ) {
      if (!campaignName) {
        setCampaignNameError('Add campaign name');
      }

      if (!retryMaxNumberValue) {
        setCampaignFieldError((prevState) => ({
          ...prevState,
          retryMaxNumber: ['Add overall retry attempts'],
        }));
      }

      if (!retriesPerDayValue) {
        setCampaignFieldError((prevState) => ({
          ...prevState,
          retriesPerDay: ['Add retries per day'],
        }));
      }

      if (!retryIntervalInMinutesValue) {
        setCampaignFieldError((prevState) => ({
          ...prevState,
          retryIntervalInMinutes: ['Add retry delay'],
        }));
      }
    } else {
      onSubmit();
    }

    if (retryMaxNumberValue) {
      setCampaignFieldError((prevState) => ({
        ...prevState,
        retryMaxNumber: [],
      }));
    }
    if (retriesPerDayValue) {
      setCampaignFieldError((prevState) => ({
        ...prevState,
        retriesPerDay: [],
      }));
    }
    if (retryIntervalInMinutesValue) {
      setCampaignFieldError((prevState) => ({
        ...prevState,
        retryIntervalInMinutes: [],
      }));
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  let mastecheValues = { agentName, templateTextFieldValue, campaignName };
  let fields = [
    {
      name: 'retryMaxNumber',
      label: t('campaignPage.overallRetryAttempt'),
      placeholder: t('campaignPage.overallRetryAttemptPlaceholder'),
      retryFieldError: campaignFieldError.retryMaxNumber || [],
      retryFieldValue: retryMaxNumberValue,
      setRetryFieldValue: setRetryMaxNumberValue,
    },
    {
      name: 'retriesPerDay',
      label: t('campaignPage.retryPerDay'),
      placeholder: t('campaignPage.retryPerDayPlaceholder'),
      retryFieldError: campaignFieldError.retriesPerDay || [],
      retryFieldValue: retriesPerDayValue,
      setRetryFieldValue: setRetriesPerDayValue,
    },
    {
      name: 'retryIntervalInMinutes',
      label: t('campaignPage.retryDelay'),
      placeholder: t('campaignPage.retryDelayPlaceholder'),
      retryFieldError: campaignFieldError.retryIntervalInMinutes || [],
      retryFieldValue: retryIntervalInMinutesValue,
      setRetryFieldValue: setRetryIntervalInMinutesValue,
    },
  ];

  return (
    <>
      <PageContainer>
        <Breadcrumb
          title={t('campaignPage.header.campaignName', { campaignName: currentCompany?.title })}
          items={BCrumb}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BlankCard>
              <form>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <CardContent>
                        <CustomFormLabel
                          sx={{
                            mt: 0,
                          }}
                          htmlFor="campaign-title">
                          {t('campaignPage.campaignName')}:
                        </CustomFormLabel>
                        <CustomTextField
                          control={control}
                          name="title"
                          onChange={handleOnChangeInput}
                          value={campaignName}
                          id="campaign-title"
                          variant="outlined"
                          fullWidth
                          required
                        />
                        {!campaignName && (
                          <Typography component="p" sx={{ color: '#FF0000', width: '300px' }}>
                            {campaignNameError}
                          </Typography>
                        )}

                        <CustomFormLabel htmlFor="campaign-agent">
                          {t('campaignPage.selectAgent')}:
                        </CustomFormLabel>
                        <FormControl fullWidth variant="outlined">
                          {/* <InputLabel
                            id="campaign-agent"
                            sx={{
                              top: '-5px',
                              '&.MuiInputLabel-shrink': {
                                top: '0px',
                              },
                            }}>
                            Agent
                          </InputLabel> */}
                          <CustomSelect
                            control={control}
                            labelId="campaign-agent"
                            name="agentId"
                            onChange={handleAgentChange}
                            id="campaign-agent-id"
                            disabled={agentListLength === 0}
                            value={agent}>
                            {agentList &&
                              agentListLength &&
                              agentList.map((agent) => (
                                <MenuItem key={agent.id} value={agent.id}>
                                  {agent.name}
                                </MenuItem>
                              ))}
                          </CustomSelect>
                        </FormControl>

                        <CampaignFileField
                          setIsFile={setIsFile}
                          campaignFieldError={campaignFieldError}
                          setCampaignFieldError={setCampaignFieldError}
                          setValue={setValue}
                          excelFieldError={excelFieldError}
                          isEditable={true}
                          setExcelFieldError={setExcelFieldError}
                        />
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: '#7C8FAC',
                            marginTop: '0px',
                            marginBottom: '20px',
                          }}>
                          {t('campaignPage.callSetting')}
                        </Typography>
                        <Button
                          onClick={() => setShowScheduleDialogFlag(true)}
                          startIcon={<IconCalendarEvent />}
                          variant="contained">
                          {t('campaignPage.changeSchedule')}
                        </Button>

                        <CampaignRetryFields
                          fields={fields}
                          setCampaignFieldError={setCampaignFieldError}
                          setValue={setValue}
                          control={control}
                        />
                      </CardContent>
                    </Grid>
                  </Grid>
                  <CampaignTextField
                    mastecheValues={mastecheValues}
                    setTemplateTextFieldValue={setTemplateTextFieldValue}
                    setValue={setValue}
                    control={control}
                  />
                  <CardContent>
                    <Stack
                      sx={{ marginTop: '30px' }}
                      direction="row"
                      justifyContent="flex-end"
                      gap={3}>
                      <Button
                        onClick={handleSubmit}
                        type="button"
                        variant="contained"
                        color="primary">
                        {t('action.save')}
                      </Button>
                      <Button onClick={() => navigator(AuthenticatedPath.CAMPAIGN)}>Cancel</Button>
                    </Stack>
                  </CardContent>
                </CardContent>
              </form>
            </BlankCard>
          </Grid>
        </Grid>

        <SchedulePopup
          showDialogFlag={showScheduleDilaogFlag}
          closeDialog={closeDialog}
          setValue={setValue}
          schedule={schedule}
          setSchedule={setSchedule}
          startTime={startTime}
          endTime={endTime}
        />
      </PageContainer>
    </>
  );
};
