import { RecipientConversationDto } from '@shared/services/apiService/apiService';
import { useEffect, useMemo, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { useGetRecipient } from './useGetRecipient';

const WS_URL = process.env.REACT_APP_API_URL;

interface WsException {
  status: string;
  message: string;
}

export const useWebSocketGetConversations = (
  campaignId: string,
  onError?: <T>(error: T) => void,
) => {
  const [isConnected, setIsConnected] = useState(false);
  const [recipients, setRecipients] = useState<RecipientConversationDto[]>([]);
  const [socket, setSocket] = useState<Socket | null>(null);

  const {
    recipientRefetch,
    recipientData,
    recipientIsLoading: isLoading,
  } = useGetRecipient(campaignId as string);

  const token = useMemo(() => localStorage.getItem('accessToken')!, []);

  const subcribeToRecipientConversations = (campaignRunId: string) => {
    socket?.emit('subscribe_to_get_recipient_conversations', {
      campaignRunId: campaignRunId,
    });
  };

  useEffect(() => {
    if (!token) return;

    const newSocket = io(`${WS_URL}/conversations`, {
      transports: ['websocket'],
      autoConnect: true,
      auth: { token },
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      setSocket(null);
    };
  }, [token]);

  useEffect(() => {
    if (recipientData) {
      setRecipients(recipientData);
    }
  }, [socket, recipientData]);

  useEffect(() => {
    if (!socket) return;

    const onConnect = () => setIsConnected(true);
    const onDisconnect = () => setIsConnected(false);

    const onGetRecipientConversations = (value: { result: RecipientConversationDto }) => {
      setRecipients((prev) => {
        const index = prev.findIndex((campaign) => campaign.id === value.result.id);
        if (index === -1) return [...prev, value.result];
        const updatedList = [...prev];
        updatedList[index] = value.result;
        return updatedList;
      });
    };

    const onException = (error: WsException) => {
      socket.disconnect();
      console.error(error);
      onError?.(error);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('get_recipient_conversations', onGetRecipientConversations);
    socket.on('exception', onException);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('get_recipient_conversations', onGetRecipientConversations);
      socket.off('exception', onException);
    };
  }, [socket, onError]);

  return {
    isConnected,
    isLoading,
    recipients,
    subcribeToRecipientConversations,
    recipientRefetch,
  };
};
